import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';

import App from './App';
// trang chủ
import HomeView from  './components/vn/home/View';

// Bài viết
import NewsView from  './components/vn/news/View';
import NewsDetail from  './components/vn/news/Detail';

//  Q&A
import QAView from  './components/vn/qa/View';
//  ProgramsView
import ProgramsView from  './components/vn/programs/View';

// Auth
import AuthLogin from  './components/vn/auth/Login';

// Không tìm thấy trang
import NotFound from  './components/vn/404/View';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App/>}>

        {/* trang chủ */}
        <Route index element={<HomeView />} />

        {/*  tin tức */}
        <Route path="/news" element={<NewsView />} />
        <Route path="/news/:id" element={<NewsDetail />} />

        {/*  Q&A */}
        <Route path="/knowledge-base" element={<QAView />} />

        <Route path="/trading-programs" element={<ProgramsView />} />

        {/* không tìm thấy trang */}
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/" >
        <Route path="/log-in" element={<AuthLogin />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
