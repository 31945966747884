import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import transparent from '../../../assets/images/frontpage-trader-transparent.png';
import work from '../../../assets/images/how-does-it-work.png';
import fidelcrest from '../../../assets/images/why-fidelcrest.png';
import Logo from '../../../assets/images/logo-bg.png';

const View = () => {
   const [data, setData] = useState({});
   const [explanation, setExplanation] = useState();
   const [trader, setTrader] = useState({type: 0, price: 0});
   const [program , setProgram ] = useState({type: 0, price: 0});

   useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://quantri.cicpropfirm.com/wp-json/custom/v1/get-custom-data/');
          // Assuming the API response is an object
          setData(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
   }, []); 

  return data? <main>
      {
         data.title_about &&
         <div className="about-bg-container">
            <div className="flex">
               <div className="flex-grow-1"></div>
               <div className="centered flex about-position">
                  <div className="about-body">
                  <div className="middle pr-30px">
                     <h1 dangerouslySetInnerHTML={{ __html: data.title_about }} />
                     <div className="about-content" dangerouslySetInnerHTML={{ __html: data.content_about }} />
                     <a href={data.link_about && data.link_about}><button className="fs-18">Start Now</button></a>
                  </div>
                  </div>
                  <div className="about-choose">
                  <div className="middle">
                     <picture>
                        <source srcSet={data.image_about && data.image_about} type="image/webp" className="about-object" />
                        <source srcSet={data.image_about && data.image_about} type="image/png" className="about-object" />
                        <img src={data.image_about && data.image_about} alt="Trader" className="about-object" />
                     </picture>
                  </div>
                  </div>
               </div>
               <div className="flex-grow-1"></div>
            </div>
            <div className="centered-box"></div>
         </div>
      }
      {
         data.program &&
         <div>
         <div className="flex">
            <div className="flex-grow-1"></div>
            <div className="centered flex relative-padding">
               <div className="centered-content">
                  <h1 className="centered-heading">{data?.program?.[program.type]?.name_trader}</h1>
                  {/* {
                     data.program.map((v, k) => (
                        program.type == k ?
                        <div key={k} className="cta-button text-center" onClick={() => setProgram({ type: program.type === 1 ? 0 : 1, price: 0 })}>
                           <span className="highlighted-text">{data?.program?.[program.type ==0 ? 1: 0]?.name_trader}</span>
                        </div> : null
                     ))
                  } */}


               </div>
            </div>
            <div className="flex-grow-1"></div>
         </div>    
         <div className="flex-container-cw program">
            <div className="flex-grow-1"></div>
            <div className="centered flex about-position">
               <div className="phases-wrapper">
                  {
                      data.program?.[program.type]?.item_program?.map((v, k) =>  
                      <div className={`phases-box ${v.color}`} key={k}>
                        {
                           v.step && <h3 className="box-title">STEP {v.step}</h3>
                        }
                        {
                           v.title && <h2 className="box-subtitle" dangerouslySetInnerHTML={{ __html: v.title }} />
                        }
                        {
                           v.content && <div className="box-description"  dangerouslySetInnerHTML={{ __html: v.content }} />
                        }
                         <div className="box-divider"></div>
                         <div className="box-details">
                           {
                              v.strategy &&
                              <div className="box-detail">
                                 <div className="detail-label">Strategy:</div>
                                 <div className="detail-value">{v.strategy}</div>
                              </div>
                           }
                           {
                              v.trading_period &&
                              <div className="box-detail">
                                 <div className="detail-label">Trading Period:</div>
                                 <div className="detail-value">{v.trading_period}</div>
                              </div>
                           }
                           {
                              v.minimum_simulated_trading_days &&
                              <div className="box-detail">
                                 <div className="detail-label">Minimum Simulated Trading Days:</div>
                                 <div className="detail-value">{v.minimum_simulated_trading_days}</div>
                              </div>
                           }
                           {
                              v.maximum_loss &&
                              <div className="box-detail">
                                 <div className="detail-label">Maximum Loss</div>
                                 <div className="detail-value">{v.maximum_loss}</div>
                              </div>
                           }
                           {
                              v.maximum_daily_loss &&
                              <div className="box-detail">
                                 <div className="detail-label">Maximum Daily Loss</div>
                                 <div className="detail-value">{v.maximum_daily_loss}</div>
                              </div>
                           }
                           {
                              v.profit_target_for_phase_1 &&
                              <div className="box-detail">
                                 <div className="detail-label">Max. Inactivity</div>
                                 <div className="detail-value">{v.profit_target_for_phase_1}</div>
                              </div>
                           }
                           {
                              v.your_share &&
                              <div className="box-detail">
                                 <div className="detail-label">You share:</div>
                                 <div className="detail-value">{v.your_share}</div>
                              </div>
                           }
                         </div>
                         <div className="cta-container">
                            <div className="cta-content">
                               <a href={v.link} className="cta-link">
                                  <button className="cta-button-cw">Start Now</button>
                               </a>
                            </div>
                         </div>
                      </div>)
                  }


               </div>
            </div>
            <div className="flex-grow-1"></div>
         </div> 
      </div>
      }
      {
         data.image_about_copy && data.title_work && <div className="flex">
            <div className="flex-grow-1"></div>
            <div className="centered flex two-columns-container">
               <div className="two-columns-item content-column">
                  <div className="pr-30px">
                     <h1 className="section-title" dangerouslySetInnerHTML={{ __html: data.title_work }}/>
                     <div className="section-subtitle" dangerouslySetInnerHTML={{ __html: data.subtitle_work }}/>
                     <div className="section-description"  dangerouslySetInnerHTML={{ __html: data.content_work }}/>
                     <a href={data.link_work } className="cta-button-cw btn btn-warning text-white">Start Now</a>
                  </div>
               </div>
               <div className="two-columns-item image-column">
                  <picture>
                     <source srcSet={data.image_about_copy} type="image/webp" className="responsive-image"/>
                     <source srcSet={data.image_about_copy} className="responsive-image"/>
                     <img src={data.image_about_copy} alt="Image" className="responsive-image"/>
                  </picture>
               </div>
            </div>
            <div className="flex-grow-1"></div>
         </div>
      }

      {
         data.trader &&
         <div className="flex">
            <div className="flex-grow-1"></div>
            <div className="centered flex position-relative-pb-40px">
               <div className="width-100">
                  <h1 className="mt-2 mb-3text-center" dangerouslySetInnerHTML={{ __html: data.title_trader }}/>
                  <div className="account-type-header">
                     <div className="account-type-strategy">
                     {
                        data.trader &&
                        data.trader.map((v, k) => (
                           <div
                              key={k}
                              onClick={() => setTrader({ type: k, price: 0 })}
                              className={`strategy-item ${k === 0 ? 'normal-strategy' : 'aggressive-strategy'} ${k == trader.type ? 'strategy-actived' : ''}`}
                           >
                              Pro Trader {v.name_trader}
                           </div>
                        ))
                     }
                     </div>
                  </div>

                  <div className="flex-direction flex-direction-divider p-0">
                  {
                     data.trader?.[trader.type]?.item_trader?.map((v, k) => (
                        <div 
                           key={k}
                           onClick={() => setTrader({...trader, price: k})} 
                           className={`account-type-item start-capital-item start-capital-item-${k+1} ${k == trader.price ? '': 'bg-white'}`}
                        > 
                           <div className="color-black">Start Capital</div>
                           <div className="color-black">{v.initial_capital && v.initial_capital}</div>
                           <div className="color-black">Normal risk</div>
                        </div>
                     ))
                  }
                  </div>
                  <div className="divider"></div>
                  {
                     data.trader?.[trader.type]?.item_trader?.[trader.price] &&
                     <div id="AccountTypeContent">
                        <div className="custom-container">
                           <div className="flex details-section">
                              <div className="info-box">
                                 <div className="info-item">
                                    <div className="info-label">Initial Capital:</div>
                                    <div className="info-value">${data.trader?.[trader.type]?.item_trader?.[trader.price]?.initial_capital}</div>
                                 </div>
                                 <div className="info-item">
                                    <div className="info-label">Trading Period:</div>
                                    <div className="info-value">{data.trader?.[trader.type]?.item_trader?.[trader.price]?.trading_period}</div>
                                 </div>
                                 <div className="info-item">
                                    <div className="info-label">Minimum Trading Days:</div>
                                    <div className="info-value">€{data.trader?.[trader.type]?.item_trader?.[trader.price]?.minimum_trading_days}</div>
                                 </div>
                              </div>
                              <div className="info-box">
                                 <div className="info-item">
                                    <div className="info-label">Profit Target:</div>
                                    <div className="info-value">{data.trader?.[trader.type]?.item_trader?.[trader.price]?.profit_target}%</div>
                                 </div>
                                 <div className="info-item">
                                    <div className="info-label">First Withdrawal:</div>
                                    <div className="info-value">{data.trader?.[trader.type]?.item_trader?.[trader.price]?.first_withdrawal}%</div>
                                 </div>
                                 <div className="info-item">
                                    <div className="info-label">Challenge Leverage:</div>
                                    <div className="info-value">{data.trader?.[trader.type]?.item_trader?.[trader.price]?.challenge_leverage}%</div>
                                 </div>
                              </div>
                              <div className="info-box">
                                 <div className="info-item">
                                    <div className="info-label">Maximum Loss:</div>
                                    <div className="info-value">{data.trader?.[trader.type]?.item_trader?.[trader.price]?.maximum_loss}</div>
                                 </div>
                                 <div className="info-item">
                                    <div className="info-label">Maximum Daily Loss:</div>
                                    <div className="info-value">{data.trader?.[trader.type]?.item_trader?.[trader.price]?.maximum_daily_loss}</div>
                                 </div>
                                 <div className="info-item">
                                    <div className="info-label">Drawdown Type:</div>
                                    <div className="info-value">{data.trader?.[trader.type]?.item_trader?.[trader.price]?.drawdown_type}</div>
                                 </div>
                              </div>
                              <div className="info-box">
                                 <div className="info-item">
                                    <div className="info-label">Challenge Fee:</div>
                                    <div className="info-value">{data.trader?.[trader.type]?.item_trader?.[trader.price]?.challenge_fee}</div>
                                 </div>
                                 <div className="info-item">
                                    <div className="info-label">Refund Fee:</div>
                                    <div className="info-value">{data.trader?.[trader.type]?.item_trader?.[trader.price]?.refund_fee}</div>
                                 </div>
                                 <div className="info-item">
                                    <div className="info-label">Profit Split:</div>
                                    <div className="info-value">{data.trader?.[trader.type]?.item_trader?.[trader.price]?.profit_split}</div>
                                 </div>
                              </div>
                           </div>
                           <div className="flex call-to-action">
                              <div className="left-section">
                                 <div className="cta-text">
                                    <div className="cta-title">{data.trader?.[trader.type]?.item_trader?.[trader.price]?.content}</div>
                                    <div className="cta-button">
                                       <a href={data.trader?.[trader.type]?.item_trader?.[trader.price]?.link_starnow}><button>Start Now</button></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  }
               </div>
            </div>
            <div className="flex-grow-1"></div>
         </div>
      }
      
      {
         data.teaser &&
         <div className="flex">
            <div className="flex-grow-1"></div>
            <div className="centered flex teaser-container">
               <div className="teaser-wrapper">

                  {
                     data.teaser.map((v, k) => 
                        <div className="teaser-box">
                           <a href="/knowledge-base" className="user-select-none">
                              <span className="material-icons-outlined icon" dangerouslySetInnerHTML={{ __html: v.icon }}/>
                              <div className="teaser-title" dangerouslySetInnerHTML={{ __html: v.title }}/>
                              <div className="teaser-value" dangerouslySetInnerHTML={{ __html: v.content }}/>
                           </a>
                        </div>
                     )
                  }

               </div>
            </div>
            <div className="flex-grow-1"></div>
         </div>
      } 

      
      <div className="flex">
         <div className="flex-grow-1"></div>
         <div className="centered flex">
            <div className="trading-program-content">
               <h1 dangerouslySetInnerHTML={{ __html: data.title_features }}/>
               <div className="program-description" dangerouslySetInnerHTML={{ __html: data.subtitle_features }}/>
               <div className="program-solution" dangerouslySetInnerHTML={{ __html: data.soloution_features }}/>
            </div>
         </div>
         <div className="flex-grow-1"></div>
      </div>
      {
         data.features &&
         <div className="flex">
            <div className="flex-grow-1"></div>
            <div className="centered flex new-class-wrapper">
               <div className="features-wrapper">

                  {
                     data.features.map((v, k) => 
                        <div className="features-box cards-shadow new-class">
                           <a href="/knowledge-base" className="user-select-none">
                              <span dangerouslySetInnerHTML={{ __html: v.icon }}/>
                              <div dangerouslySetInnerHTML={{ __html: v.title }}/>
                              <div dangerouslySetInnerHTML={{ __html: v.content }}/>
                           </a>
                        </div>
                     )
                  }

               </div>
            </div>
            <div className="flex-grow-1"></div>
         </div>
      }     
      {
         data.title_why && data.image_why &&
         <div className="flex">
            <div className="flex-grow-1"></div>
            <div className="centered flex new-class-wrapper">
               <div className="two-columns-item new-class">
                  <picture>
                     <source srcSet={data.image_why} type="image/webp"/>
                     <source srcSet={data.image_why} type="image/png"/>
                     <img src={data.image_why} alt="Image"/>
                  </picture>
               </div>
               <div className="two-columns-item">
                  <div className="pr-30px">
                     <h1 dangerouslySetInnerHTML={{ __html: data.title_why }}/>
                     <div></div>
                     <div dangerouslySetInnerHTML={{ __html: data.content_why }}/>
                     <a href={data.link_why}><button>Start Now</button></a>
                  </div>
               </div>
            </div>
            <div className="flex-grow-1"></div>
         </div> 
      }
      {
         data.explanations && <div className="flex">
            <div className="flex-grow-1"></div>
            <div className="centered flex about-position">
               <div className="trading-objectives my-4">
                  <h1 dangerouslySetInnerHTML={{ __html: data.title_explanations }}/>
                  <div className="flex-direction">
                  {
                     data.explanations.map((item, index) =>
                        item.is_home == 1 ? (
                           explanation == index ? (
                           <div key={index} style={{ flex: '1 1 48%', textAlign: 'left', cursor: 'pointer', userSelect: 'none', padding: '0px', fontWeight: 700 }}>
                              <div style={{ border: '1px solid rgb(204, 204, 204)', borderRadius: '10px 10px 0px 0px', background: 'rgb(255, 170, 6)' }}>
                                 <div style={{ position: 'relative' }}>
                                 <h3  style={{ padding: '7px' }} dangerouslySetInnerHTML={{ __html: item.question }} />
                                 <div className="material-icons-outlined" style={{ position: 'absolute', right: '0px', top: '0px', padding: '5px 10px' }}>expand_less</div>
                                 </div>
                              </div>
                              <div style={{ border: '1px solid rgb(204, 204, 204)', padding: '0px 10px', fontWeight: 500, borderRadius: '0px 0px 10px 10px', background: 'white' }}>
                                 <div style={{ padding: '5px 10px' }} dangerouslySetInnerHTML={{ __html: item.answer }} />
                              </div>
                           </div>
                           ) : (
                           <div key={index} onClick={() => setExplanation(explanation == index ? '' : index)} className="objective-item">
                              <div className="objective-content">
                                 <h3 dangerouslySetInnerHTML={{ __html: item.question }} />
                                 <div className="material-icons-outlined">expand_more</div>
                              </div>
                           </div>
                           )
                        ) : <div>sss, </div>
                     )
                  }
                  </div>
               </div>
            </div>
            <div className="flex-grow-1"></div>
         </div>
      }
      {
         data.statistical && <div className="flex bg-51-54-69">
            <div className="flex-grow-1"></div>
            <div className="centered flex about-position new-class">
               <div className="flex flex-news">
                  {
                     data.statistical.map((v, k) => <div className="new-class-item">
                        <div>{v.value}</div>
                        <div>{v.title}</div>
                     </div>) 
                  }
               </div>
            </div>
            <div className="flex-grow-1"></div>
         </div>
      }
  </main>: <></>;
};

export default View;
