import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from "react-router-dom";

const Detail = () => {
  let { id } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
     const fetchData = async () => {
       try {
         const response = await axios.get('https://quantri.cicpropfirm.com/wp-json/custom/v1/get-post-details/'+id);
         // Assuming the API response is an object
         setData(response.data);
       } catch (error) {
         console.error('Error fetching data:', error);
       }
     };
 
     fetchData();
  }, []);
  return <main>
    <div class="hero-section">
        <div class="flex">
            <div class="flex-grow-1"></div>
            <div class="centered flex news-section">
                <div class="content-wrapper">
                    <div class="content-inner">
                    <h1 class="news-title">{data.title}</h1>
                    </div>
                </div>
            </div>
            <div class="flex-grow-1"></div>
        </div>
    </div>
    <div class="flex" style={{marginYop: '20px'}}>
   <div class="flex-grow-1"></div>
   <div class="centered flex" style={{position: 'relative', paddingBottom: '30px'}}>
      <div>
         <div class="news" dangerouslySetInnerHTML={{ __html: data.content }}/>
      </div>
   </div>
   <div class="flex-grow-1"></div>
</div>
  </main>;
};

export default Detail;
