import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Logo from '../assets/images/logo-bg.png';

function Header() {
   const [menuData, setMenuData] = useState([]);
   const [menu, setMenu] = useState(0);
 
   useEffect(() => {
     const fetchMenuData = async () => {
       try {
         const response = await axios.get('https://quantri.cicpropfirm.com/wp-json/wp/v1/menu/');
         setMenuData(response.data);
       } catch (error) {
         console.error('Error fetching menu:', error);
       }
     };
 
     fetchMenuData();
   }, []);

    return (
      <header>
         <div className="flex-1"></div>
         <div className="centered">
            <table border="0" className="width-100">
               <tbody>
                  <tr>
                     <td className="nav-logo">
                        <table className="align-middle">
                           <tbody>
                              <tr>
                                 <td className="p-0">
                                    <span className="top-menu-item">
                                       <div className="nav-left">
                                          <table border="0" className="d-block">
                                             <tbody>
                                                <tr>
                                                   <td>
                                                      <Link to={'/'} className="top-svg-item d-flex align-center">
                                                         <img className="img-logo" src={Logo}/>
                                                      </Link>
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </span>
                                 </td>
                                 <td className="p-0"><span onClick={() =>setMenu(1)} className="span-mobile top-svg-item material-icons">menu</span></td>
                              </tr>
                           </tbody>
                        </table>
                     </td>
                     <td className="nav-right">
                        <table className="table-nav-right">
                           <tbody>
                              <tr>
                                 {
                                    menuData.map((menuItem, index) => (
                                       
                                       menuItem.url == '/log-in' ? 
                                       <td className="top-menu-item login-top-menu-item">
                                          <Link className="top-menu-item" to={menuItem.url}>
                                             <div className="d-flex align-center">
                                                <div className="material-icons-outlined">person</div>
                                                <div className="text-login">{menuItem.title}</div>
                                             </div>
                                          </Link>
                                       </td> :
                                       <td key={index} className="td-dt top-menu-item-header nav-top-menu-item">
                                          <Link className="td-dt top-menu-item" to={menuItem.url}>
                                             <div className="menu-item">{menuItem.title}</div>
                                          </Link>
                                       </td> 
                                    ))
                                 }
                              </tr>
                           </tbody>
                        </table>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div className="flex-1"></div>
         {
            menu == 1 && 
            <div style={{position: 'absolute', zIndex: 3, left: '0px', top: '0px',  width: '100%', backgroundColor: 'rgb(0, 0, 0)', color: 'rgb(224, 224, 224)', maxHeight: '94px'}}>
            <div class="flex">
               <div class="centered flex" style={{position: 'relative', paddingBottom: '0'}}>
                  <table border="0" style={{width: '100%', background: 'rgb(0, 0, 0)'}}>
                     <tbody>
                        <tr>
                           <td style={{cursor: 'pointer', padding: '10px 0px 0px', width: '60%', textAlign: 'left'}}>
                              <div style={{transform: 'translateX(-8px)', textAlign: 'left', margin:' 0px 0px 0px 10px'}}>
                                 <table border="0" style={{display: 'inline-block'}}>
                                    <tbody>
                                       <tr>
                                          <td>
                                             <div class="top-svg-item" style={{display: 'flex', alignItems: 'center'}}>
                                                <div>
                                                      <Link to={'/'} className="top-svg-item d-flex align-center">
                                                         <img className="img-logo" src={Logo}/>
                                                      </Link>
                                                </div>
                                             </div>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </td>
                           <td style={{cursor: 'pointer', padding: '10px 20px 0px 0px', width: '40%', textAlign: 'right', fontSize: '16px', color: 'rgb(224, 224, 224)', fontWeight: '700'}}><span class="material-icons" onClick={() =>setMenu(0)} style={{userSelect: 'none'}}>close</span></td>
                        </tr>
                     </tbody>
                  </table>
               </div>
               <div style={{flexGrow: 1}}></div>
                  <div class="centered flex" style={{position: 'relative', paddingBottom: '30px', height: '100vh', backgroundColor: 'white'}}>
                     <div style={{margin: '110px 0px 0px'}}>
                        
                        {
                           menuData.map((menuItem, index) => (
                              
                              <Link to={menuItem.url} onClick={() =>setMenu(0)}>
                                 <div style={{padding: '10px 20px 10px 10px', whiteSpace: 'nowrap', fontSize: '16px', fontWeight: '700', color: 'black',}}>
                                    <div style={{padding: '0px 0px 0px 5px',  textAlign: 'start'}}>{menuItem.title}</div>
                                 </div>
                              </Link>
                              
                           ))
                        }
                     </div>
                  </div>
               </div>
            </div>
         }
      </header>);
}

export default Header;