import React from 'react';
import Logo from '../../../assets/images/logo-bg.png';

const Login = () => {
  return (
    <div className="front">
      <div className="flex-grow"></div>
      <div className="centered-front">
        <div className="logo-container">
          <a href="/">
            <div className="logo-content">
              <img className="logo1" alt="logo" src={Logo} />
            </div>
          </a>
        </div>
        <div className="login-section">
          <h3>Login</h3>
          <form>
            <div className="input-container">
              <input id="userid" name="userid" className="front-input" placeholder="Email address" />
              <input id="password" name="password" type="password" className="front-input" placeholder="Password" />
            </div>
            <a href="/reset-password" className="forgot-password">
              <p>Forgot password</p>
            </a>
            <input type="submit" className="button-front-active no-webkit" value="Log in" />
          </form>
          <div className="login-info">
            <p><a target="_blank" rel="noreferrer" href="https://my.challenge.fidelcrest.com/index.php?p=login">Login here</a><span>&nbsp;&nbsp;to the old trader area to<br />manage my old Fidelcrest accounts</span></p>
            <br />
            <a href="/">
              <p>Back</p>
            </a>
          </div>
        </div>
      </div>
      <div className="flex-grow"></div>
    </div>
  );
}

export default Login;
