import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

const PageNotFound = () => {
    const [data, setData] = useState([]);
 
    useEffect(() => {
       const fetchData = async () => {
         try {
           const response = await axios.get('https://quantri.cicpropfirm.com/wp-json/custom/v1/get-posts-by-category/18');
           // Assuming the API response is an object
           setData(response.data);
         } catch (error) {
           console.error('Error fetching data:', error);
         }
       };
   
       fetchData();
    }, []);
  return <main>
    <div class="hero-section">
        <div class="flex">
            <div class="flex-grow-1"></div>
            <div class="centered flex news-section">
                <div class="content-wrapper">
                    <div class="content-inner">
                    <h1 class="news-title">News</h1>
                    </div>
                </div>
            </div>
            <div class="flex-grow-1"></div>
        </div>
    </div>
    <div class="flex news-section">
        <div class="flex-grow-1"></div>
        <div class="centered flex">
            <div class="content-wrapper-news">
                <h1 class="section-title color-black">News &amp; Blog</h1>
                <div class="card-container">

                    {
                        data && data.map((v, k) => 
                        <div class="card">
                            <div class="cards-shadow">
                                <Link to={`/news/${v.id}`}>
                                    <div class="card-image">
                                        <img src={v.thumbnail_url} alt={v.title}/>
                                    </div>
                                    <div class="card-content">
                                        <h3>{v.title}</h3>
                                    </div>
                                </Link>
                            </div>
                        </div>)
                    }

                    

                </div>
            </div>
        </div>
        <div class="flex-grow-1"></div>
    </div>
  </main>;
};

export default PageNotFound;
