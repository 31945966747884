import { Link } from "react-router-dom";

import Logo from '../assets/images/logo-bg.png';
import ImgCC from '../assets/images/mc-visa.png';
import skrill from '../assets/images/skrill.png'
import neteller from '../assets/images/neteller.png';
import laurels from '../assets/images/laurel.png';

function Footer() {
    return <footer>
        <div className="flex-grow-1"></div>
        <div className="centered footer-color-white">
            <div className="footer-content">
                <div className="footer-column footer-column-passion">
                    <h3>Passion for Trading</h3>
                    <div className="p-0 m-15px-0-9px">
                        <div>
                        <div>
                            <table border="0" className="d-block">
                                <tbody>
                                    <tr>
                                    <td>
                                        <div className="top-svg-item footer-center">
                                            <div>
                                                <img className="img-logo" src={Logo}/>
                                            </div>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                    <div className="f-content">Fidelcrest was founded in 2018 by a team of Forex traders and professionals. Our management team has over 10 years of experience working at international brokerage companies.</div>
                </div>
                
                <div className="footer-column footer-column-trading">
                    <h3>Trading Challenge</h3>
                    <a href="/knowledge-base"><div className="top-menu-item">Knowledge Base</div></a>
                    <a href="/profit-splits"><div className="top-menu-item">Programs & Profit Share</div></a>
                    <a href="/trading-programs"><div className="top-menu-item">Detailed Rules & Objectives</div></a>
                    <a href="/refund-and-cancellation-policy"><div className="top-menu-item">Refund and Cancellation Policy</div></a>
                    <a href="/scaling-plan"><div className="top-menu-item">Scaling Plan</div></a>
                </div>
                
                <div className="footer-column footer-column-about">
                    <h3>About Fidelcrest</h3>
                    <a href="/about-fidelcrest"><div className="top-menu-item">About Us</div></a>
                    <a href="/partners"><div className="top-menu-item">Partners</div></a>
                    <a href="/fidelcrest-affiliate-program"><div className="top-menu-item">Affiliates</div></a>
                    <a href="/privacy-policy"><div className="top-menu-item">Privacy Policy</div></a>
                    <a href="/general-terms-and-conditions"><div className="top-menu-item">Terms and Conditions</div></a>
                    <a href="/careers"><div className="top-menu-item">Careers</div></a>
                </div>
                <div className="footer-column footer-column-opening">
                    <h3>Opening Hours</h3>
                    <div>Mon – Fri: 24h</div>
                    <div className="color-rgb-255-6">Sat: Closed</div>
                    <div className="color-rgb-255-6">Sun: Closed</div>
                    <div>Customer Support:</div>
                    <div className="color-rgb-255-6">support(at)fidelcrest.com</div>
                    <a className="top-menu-item" href="https://direct.lc.chat/10945667" target="_blank"><div>Start Chat</div></a>
                    <a className="top-menu-item" href="https://direct.lc.chat/10945667" target="_blank"><div>Create a Ticket</div></a>
                </div>
            </div>
            <div className="footer-flex-container">
                <div className="footer-column">
                    <h3>Tools & Platforms</h3>
                    <a className="top-menu-item" href="https://trade.fidelcrest.com?lang=en" target="_blank">Fidelcrest Trader Area</a>
                    <a className="top-menu-item" href="https://trade.fidelcrest.com?lang=en" target="_blank">Instruments</a>
                    <a className="top-menu-item" href="https://trade.fidelcrest.com?lang=en" target="_blank">Economic Calendar</a>
                </div>
                
                <div className="footer-column">
                    <h3 className="color-rgb-255-6-pd-10-0">LiveChat</h3>
                </div>
                <div className="footer-column footer-trustpilot">
                    <h3>TrustPilot</h3>
                    <div id="trustbox" className="trustpilot-widget" data-locale="en-US" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="5f523d06c358dd00014b8f46" data-style-height="24px" data-style-width="100%" data-theme="dark">
                        <iframe title="Customer reviews powered by Trustpilot" loading="auto" src="https://widget.trustpilot.com/trustboxes/5419b732fbfb950b10de65e5/index.html?templateId=5419b732fbfb950b10de65e5&amp;businessunitId=5f523d06c358dd00014b8f46#locale=en-US&amp;styleHeight=24px&amp;styleWidth=100%25&amp;theme=dark" ></iframe>
                    </div>
                </div>
                <div className="footer-column footer-payment-methods">
                    <h3>Payment Methods</h3>
                    <div className="payment-icons-container">
                        <img alt="cc" src={ImgCC}/>
                        <img alt="skrill" src={skrill}/>
                        <img alt="neteller" src={neteller}/>
                    </div>
                </div>
            </div>
           <div className="footer-columns-container">
                <div className="footer-column">
                    <a to="/news/fidelcrest-awarded-at-forex-expo-2021">
                        <div className="footer-image-container">
                            <img alt="laurels" src={laurels}/>
                            <div className="footer-text">BEST<br/>MICRO FOREX<br/>IN EUROPE<br/>2021</div>
                        </div>
                    </a>
                </div>
                <div className="footer-column">
                    <a href="/news/fidelcrest-awarded-at-forex-expo-2021">
                        <div className="footer-image-container">
                        <img alt="laurels" src={laurels}/>
                        <div className="footer-text">PEOPLE'S<br/>CHOICE<br/>AWARD<br/>2021</div>
                        </div>
                    </a>
                </div>
                <div className="footer-column">
                    <a href="/news/fidelcrest-awarded-best-proprietary-trading-firm-2022">
                        <div className="footer-image-container">
                        <img alt="laurels" src={laurels}/>
                        <div className="footer-text">BEST<br/>PROPRIETARY<br/>TRADING FIRM<br/>UK 2022</div>
                        </div>
                    </a>
                </div>
            </div>            
            <div className="custom-footer new-class-end">
                <p>Fidelcrest is the trading name of Fidelcrest Ltd, Cyprus.</p>
                <p>Fidelcrest.com and trade.fidelcrest.com are owned and operated by Fidelcrest Ltd, registered office at Arch. Makariou III &amp; 1-7 Evagorou, MITSI 3, 1st floor, office 102 C, 1065 Nicosia, Cyprus (Company number HE413263)</p>
                <div className="risk-policy-link"><span>Risk Policy</span></div>
                <br/>
                <div className="rights-reserved">© 2018 - 2023 All Rights Reserved by Fidelcrest Group</div>
            </div> 
        </div>
        <div className="flex-grow-1"></div>
    </footer>;
}

export default Footer;