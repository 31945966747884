import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

const View = () => {
   const [data, setData] = useState({});
   const [explanation, setExplanation] = useState();
   useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://quantri.cicpropfirm.com/wp-json/custom/v1/get-custom-data/');
          // Assuming the API response is an object
          setData(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
   }, []); 
  return <main>
    <div class="hero-section">
        <div class="flex">
            <div class="flex-grow-1"></div>
            <div class="centered flex news-section">
                <div class="content-wrapper">
                    <div class="content-inner">
                    <h1 class="news-title">Knowledge Base</h1>
                    </div>
                </div>
            </div>
            <div class="flex-grow-1"></div>
        </div>
    </div>
    
         
         

         
      {
         data.explanations && <div className="flex">
            <div className="flex-grow-1"></div>
            <div className="centered flex about-position">
               <div className="trading-objectives my-4">
                  <h1>Questions & Answers</h1>
                  <div className="flex-direction">
                  {
                     data.explanations.map((item, index) =>
                        explanation == index ? (
                        <div key={index} style={{ flex: '1 1 48%', textAlign: 'left', cursor: 'pointer', userSelect: 'none', padding: '0px', fontWeight: 700 }}>
                           <div style={{ border: '1px solid rgb(204, 204, 204)', borderRadius: '10px 10px 0px 0px', background: 'rgb(255, 170, 6)' }}>
                              <div style={{ position: 'relative' }}>
                              <h3  style={{ padding: '7px' }} dangerouslySetInnerHTML={{ __html: item.question }} />
                              <div className="material-icons-outlined" style={{ position: 'absolute', right: '0px', top: '0px', padding: '5px 10px' }}>expand_less</div>
                              </div>
                           </div>
                           <div style={{ border: '1px solid rgb(204, 204, 204)', padding: '0px 10px', fontWeight: 500, borderRadius: '0px 0px 10px 10px', background: 'white' }}>
                              <div style={{ padding: '5px 10px' }} dangerouslySetInnerHTML={{ __html: item.answer }} />
                           </div>
                        </div>
                        ) : (
                        <div key={index} onClick={() => setExplanation(explanation == index ? '' : index)} className="objective-item">
                           <div className="objective-content">
                              <h3 dangerouslySetInnerHTML={{ __html: item.question }} />
                              <div className="material-icons-outlined">expand_more</div>
                           </div>
                        </div>
                        )
                     )
                  }
                  </div>
               </div>
            </div>
            <div className="flex-grow-1"></div>
         </div>
      }


  </main>;
};

export default View;
